.swiper {
  max-width: 1280px;
  height: 450px;
  flex-direction: row;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide .img-container {
  width: 58%;
  padding: 4rem;
}
.swiper-slide .text-container {
  width: 42%;
  padding: 4rem;
  padding-left: 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.swiper-button-next,
.swiper-button-prev {
  color: #cd8265;
}

.swiper-pagination-bullet-active {
  background-color: #cd8265;
}

@media screen and (max-width: 640px) {
  .swiper {
    width: 360px;
    height: 600px;
  }
  .swiper-slide {
    flex-direction: column;
  }

  .swiper-slide .img-container {
    width: 100%;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 0px;
  }
  .swiper-slide .text-container {
    width: 100%;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0px;
  }
}
